<template>
  <form
    ref="form"
    :class="formClass"
    @submit="handleSubmit"
  >
    <slot />
  </form>
</template>
<script>
export default {
  name: 'DynamicForm',
  props: {
    apiUrl: {
      type: String,
      required: true
    },
    method: {
      type: String,
      default: 'POST'
    },
    formClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    async handleSubmit (ev) {
      ev.preventDefault()
      const formData = new FormData(this.$refs.form)
      const siteUri = window.location.pathname.slice(1).split("/")[0];
      const token = window.localStorage.getItem(`gg-${siteUri}-token`)
      const res = await fetch(this.apiUrl, {
        method: this.method,
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      }).then(res => res.json())
      this.$emit('updated', res)
    }
  }
}
</script>
